<template>
  <div class="shadow-2xl rounded-2xl m-8 font-thin bg-gray-100 p-10 max-w-sm mx-auto sm:max-w-lg
      sm:text-lg md:max-w-2xl">
    <div class="rounded-xl bg-white pt-4
        pb-4
        mb-4
        text-center text-blue-400
        pt-3
        shadow-md
      "
    >
      <h1 class="text-4xl font-medium">Welcome New</h1>
      <h3 class="text-gray-900">Register here</h3>
    </div>
    <div class="sm:grid sm:grid-cols-2">
      <div class="pt-3 pb-3 space-y-3">
        <div class="">
          <label class="inline-block w-20">Name</label>
          <input
            type="text"
            required
            autofocus
            class="
              invalid:bg-yellow-400
              font-thin
              focus:bg-blue-100
              px-2
              border
              rounded
              border-grey-300
            "
            v-model="name"
          />
        </div>
        <div class="">
          <label class="inline-block w-20">Email</label>
          <input
            type="email"
            required
            class="
              invalid:bg-yellow-400
              font-thin
              focus:bg-blue-100
              px-2
              border
              rounded
              border-grey-300
            "
            @keyup.enter="click"
            v-model="email"
          />
        </div>
        <div class="pt-2">
          <button
            class="
              shadow-lg
              hover:shadow-xl
              hover:bg-red-300
              hover:-translate-y-1
              cursor-pointer
              hover:transform
              hover:transition
              active:bg-red-500
              active:translate-y-0.5
              uppercase
              tracking-wider
              inline-block
              bg-red-400
              rounded-3xl
              px-3
              py-1
              border-2 border-red-500
              font-light
            "
            @click="click"
          >
            Interested
          </button>
        </div>
      </div>
      <div class="py-4 sm:px-4">
        <p class="py-2" v-if="interested">
          There are {{ interested.length }} people interested
        </p>
        <div
          class="bg-white rounded-xl px-4 py-2 mb-2 shadow-xl grid grid-cols-8"
          v-for="att in interested"
          :key="att.email"
        >
          <div class="col-span-7">✅ {{ att.name }}</div>
          <a class="cursor-pointer hover:translate-y-0.5" @click="del(att.name)">🗑</a
          >
        </div>
      </div>
    </div>
    <div v-observeme class="fade">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id error doloremque autem quidem. Blanditiis, quisquam ipsum officia similique sed veniam labore ea soluta numquam repellat tempore, autem rem quod assumenda?
    </div>
    <div v-observeme class="fade">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id error doloremque autem quidem. Blanditiis, quisquam ipsum officia similique sed veniam labore ea soluta numquam repellat tempore, autem rem quod assumenda?
    </div>
    <div class="fade">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id error doloremque autem quidem. Blanditiis, quisquam ipsum officia similique sed veniam labore ea soluta numquam repellat tempore, autem rem quod assumenda?
    </div>
    
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    console.log('creating observer')
    this.observer = new IntersectionObserver(
      this.onElementObserved, 
      {
        root: null,
        threshold: 0.5,
      }
    )
    // const elements = this.$el.querySelectorAll(".fade")
    // elements.forEach( el => {
    //   this.observer.observe(el)
    //   console.log("observing %s", el)
    // })
  },
  data() {
    return {
      observer : null,
      interested: [],
      name: "hello",
      email: "hello@here.com",
    };
  },
  directives: {
    observeme : {
      mounted: (el, binding) => {
          // console.log("registering with observer", el)
          console.log("binding.value", binding.value)
          console.log("binding.instance.observer", binding.instance.observer)
          const observer = binding.value ? binding.value : binding.instance.observer
          observer.observe(el)
      }
    }
  },
  methods: {
    click() {
      this.interested.push({ name: this.name, email: this.email });
      this.name = "enter name " + (this.interested.length + 1);
      this.email = "here@here.com";
    },
    del(name) {
      this.interested = this.interested.filter((item) => item.name !== name);
    },
    // https://github.com/Akryum/vue-observe-visibility
    visibilityChanged(isVisible) {
      console.log("visible: %s", isVisible)
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting}) => {
          console.log(target)  
          if (isIntersecting) {
            target.classList.add("textFadeUp");
            this.observer.unobserve(target)
        } else {
            // target.classList.remove("textFadeUp");
          }
      });
    }
  },
};


</script>



<style>

.fade {
  @apply py-4;
  opacity: 0;
  transform: translateY(60px); 
  transition: opacity 2s, transform 2s;
}
@media (prefers-reduced-motion: no-preference) {
  .textFadeUp {
    opacity: 1;
    transform: translateY(0px);
  }
}

</style>
